import { v1 } from "uuid";
import {
  GA4_PURCHASE,
  GA4_SHARE,
  GA4_TRANSACTION_COST_OPTED_IN,
} from "./helpers";
import analytics from "../../lib/analytics";
import { hashData } from "../../lib/cryptoUtils";

export const logGa4Ecommerce = (data) => {
  try {
    const uuid = v1();
    const {
      amount,
      currency,
      recurring,
      pageType,
      gaItem,
      slug,
      email,
      phone,
      isTCCovered,
    } = data;
    const price = parseFloat(amount).toFixed(2);
    analytics.track(GA4_PURCHASE, {
      transaction_id: uuid,
      value: price,
      currency,
      slug,
      action_page_type: pageType,
      items: [
        {
          item_id: gaItem?.id,
          item_name: gaItem?.id,
          item_list_name: gaItem?.listName,
          item_variant: recurring,
          item_category: pageType,
          price,
          quantity: 1,
        },
      ],
      enhanced_conversion_data: getEnhancedConversionsData({ email, phone }),
    });
    // Send a custom event when transaction cost is covered for the purchase
    if (isTCCovered) {
      analytics.track(GA4_TRANSACTION_COST_OPTED_IN, {
        transaction_id: uuid,
        value: price,
        currency,
        page_type: pageType,
      });
    }
  } catch (error) {
    console.log("Error logging GA4 purchase event", error);
  }
};

export const logShareToGA = (data) => {
  const { type, slug, pageType } = data;
  analytics.track(GA4_SHARE, {
    content_type: "link",
    method: getShareType(type),
    slug,
    action_page_type: pageType,
  });
};

const getShareType = (shareType) => {
  switch (shareType) {
    case "f":
      return "facebook";
    case "t":
      return "twitter";
    case "e":
      return "email";
    case "w":
      return "whatsapp";
    default:
      return "other";
  }
};

export const getEnhancedConversionsData = (data) => {
  const { email, phone } = data;
  if (!email && !phone) {
    return {};
  }

  const hashedEmail = hashData(email?.trim().toLowerCase()); // Normalize before hashing
  const hashedPhoneNumber = phone ? hashData(phone?.replace(/\D/g, "")) : ""; // Remove non-digits for phone number

  return {
    sha256_email_address: hashedEmail, // hashed email
    ...(hashedPhoneNumber && { sha256_phone_number: hashedPhoneNumber }), // hashed phone number
  };
};

export const measurementId = "G-PMEW5CDHC1";
